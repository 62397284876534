<template>
  <v-card outlined elevation="0" class="pa-0" rounded="lg">
    <v-card-title class="justify-space-between">
      <div class="d-flex">
        <v-btn
          class="text-h5 font-weight-medium primary--text text-none"
          text
          to="/assessments/create"
        >
          <v-icon left large>
            {{ mdiPlus }}
          </v-icon>
          Create Assessment
        </v-btn>
      </div>
      <div>
        <v-text-field
          outlined
          v-model="searchAssessment"
          :prepend-inner-icon="mdiMagnify"
          dense
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </div>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-data-table
        :headers="assessmentHeaders"
        :items="assessments"
        @click:row="navigateToTest"
        :loading="customerTestsLoading"
        :search="searchAssessment"
        sort-by="date"
      >
        <template v-slot:item.name="{ item }">
          <div text class="text-none text-body-1">
            {{ item.name }}
          </div>
        </template>
        <template v-slot:item.date="{ item }">
          <div>
            {{ formatDate(item.date) }}
            <!-- {{ item.date }} -->
          </div>
        </template>
        <template v-slot:item.progress="{ item }">
          <v-progress-linear
            rounded
            :value="(item.progress * 100) / item.candidates"
            height="15"
          ></v-progress-linear>
        </template>
        <template v-slot:item.archive="{ item }">
          <v-btn icon @click.stop="archiveCandidate(item)">
            <v-icon color="danger">
              {{ mdiPackageDown }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:item.invaction="{ item }">
          <v-btn @click.stop="inviteCandidate(item)" color="primary" text>
            <v-icon icon color="primary" class="">
              {{ mdiCloudUpload }}
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:body.append>
          <tr v-if="isEmptyState" class="empty_row">
            <td></td>
            <td></td>
            <td class="d-flex flex-column py-5" style="height: auto">
              <v-row align="center" justify="center" no-gutters>
                <v-img
                  src="~@/assets/images/campaign/createtest.png"
                  max-width="350"
                  max-height="400"
                ></v-img>
              </v-row>

              <v-btn
                class="text-h5 font-weight-medium primary--text text-none"
                text
                large
                to="/assessments/create"
              >
                <v-icon left x-large>
                  {{ mdiPlus }}
                </v-icon>
                Create Assessment
              </v-btn>
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiPlus, mdiPackageDown, mdiCloudUpload, mdiMagnify } from "@mdi/js";
import moment from "moment";
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { ApiCallStatus } from "@/utils/api";
export default {
  data() {
    return {
      mdiPlus,
      mdiMagnify,
      mdiPackageDown,
      mdiCloudUpload,
      assessmentHeaders: [
        {
          text: "Test Name",
          value: "name",
        },
        {
          text: "Date",
          value: "date",
          sort: (a, b) => b - a,
        },
        {
          text: "Candidates",
          value: "candidates",
          align: "center",
        },
        {
          text: "Progress",
          value: "progress",
          sort: (a, b) => {
            // undefined on old tests
            // not needed in prod
            a = a ?? 0;
            b = b ?? 0;
            return b - a;
          },
        },
        {
          text: "Archive",
          value: "archive",
          align: "center",
          sortable: false,
        },
        {
          text: "Invite Candidates",
          value: "invaction",
          align: "center",
          sortable: false,
        },
      ],
      assessments: [],
      emptyFetchTimeout: null,
      customerTestsLoading: true,
      searchAssessment: "",
    };
  },
  props: {},
  computed: {
    ...mapState("recruiter", [
      "recruiter",
      "allTests",
      "fetchCustomerTestsStatus",
    ]),
    ...mapState("customer", ["customer"]),
    isEmptyState() {
      return (
        this.assessments.length === 1 &&
        this.assessments[0].tags.includes("purpose/sample")
      );
    },
  },
  components: {},
  methods: {
    ...mapActions("recruiter", [
      "fetchRecruiterProfile",
      "fetchCustomerAllTest",
    ]),
    ...mapActions("notifs", ["addNotif"]),
    ...mapActions("customer", ["fetchCustomer"]),
    archiveCandidate() {
      this.addNotif({
        text: "Will be available soon!",
        type: "info",
      });
    },
    inviteCandidate(item) {
      this.$router.push({
        path: `/assessments/${item.url}`,
        query: {
          invite: true,
        },
      });
    },
    navigateToTest(item) {
      this.$router.push({
        path: `/assessments/${item.url}`,
      });
    },
    formatDate(timep) {
      return moment.unix(timep / 1000).format("DD MMM YY");
    },
    populateAssessments() {
      this.fetchCustomerAllTest().then((res) => {
        // console.log(res?.getTestPreviewList());
        this.assessments = [];
        this.allTests.forEach((testPreview) => {
          this.assessments.push({
            id: testPreview?.getId(),
            name: testPreview?.getTitle(),
            url: testPreview?.getUrl(),
            candidates:
              testPreview?.getMetaData()?.getTestMeta()?.getInvited() ?? 0,
            progress: testPreview?.getMetaData()?.getTestMeta()?.getCompleted(),
            date: testPreview?.getCreatedOn(),
            tags: testPreview?.getMetaData()?.getTagsList(),
          });
        });
        clearTimeout(this.emptyFetchTimeout);
      });
    },
  },
  created() {
    this.populateAssessments();
    if (!this.customer) {
      this.fetchCustomer().then((r) => {
        console.log(`customer`, this.customer?.toObject());
      });
    }
    if (!this.recruiter) {
      this.fetchRecruiterProfile((r) => {
        console.log(`recruiter`, this.recruiter?.toObject());
      });
    }
  },
  watch: {
    fetchCustomerTestsStatus() {
      if (this.fetchCustomerTestsStatus === ApiCallStatus.SUCCESS) {
        this.customerTestsLoading = false;
      } else if (this.fetchCustomerTestsStatus === ApiCallStatus.ERROR) {
        this.customerTestsLoading = false;
        this.$store.dispatch("notifs/addNotif", {
          text: "Error loading assessments, reload!",
          type: "error",
        });
      } else {
        this.customerTestsLoading = true;
      }
    },
    assessments() {
      if (this.assessments.length <= 0) {
        console.log("empty assessments");
        this.emptyFetchTimeout = setInterval(
          () => this.populateAssessments(),
          7500
        );
      } else {
        clearTimeout(this.emptyFetchTimeout);
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.emptyFetchTimeout);
  },
};
</script>
<style>
tr.empty_row,
tr.empty_row:hover {
  width: 100%;
  cursor: auto;
  background: #fff !important;
}
tr {
  cursor: pointer;
}
</style>
